import React, { useRef } from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Header from "../components/Header"
import { useHasBeenVisible } from "../hooks/useVisibility"
import PageLayout from "../layouts/PageLayout"
import SEO from "../components/seo"
import "../scss/pages/machines.scss"

const BelowTheFold = loadable(() =>
  import("../components/BelowTheFold/Machines")
)

const MachinesPage = ({ data, location: { pathname } }) => {
  const { seo, content } = data.page.childMarkdownRemark.frontmatter
  const { machines, services, blockImage } = data
  const mainVisible = useRef()
  const hasScrolled = useHasBeenVisible(mainVisible)
  return (
    <PageLayout>
      <SEO
        title="Machines | West Michigan Fab Corp"
        description="Browse the various equipment that we use to fabricate precision metal products!"
        ogImage={seo.ogImage.childImageSharp.fixed}
      />
      <Header
        subheading="Our Machines"
        heading="Tools of the Trade"
        background={content.headerSection.background}
        color="blue"
      />
      <main ref={mainVisible}>
        {hasScrolled && (
          <BelowTheFold
            mainSection={content.mainSection}
            blockImage={blockImage}
            CTA={content.CTA}
            machines={machines}
            services={services}
          />
        )}
      </main>
    </PageLayout>
  )
}

export const IndexQuery = graphql`
  query MachinesPageQuery {
    page: file(sourceInstanceName: { eq: "pages" }, name: { eq: "about" }) {
      id
      childMarkdownRemark {
        fields {
          slug
        }
        frontmatter {
          seo {
            title
            metaDescription
            ogImage {
              childImageSharp {
                fixed(width: 1200, height: 630, quality: 70) {
                  src
                }
              }
            }
          }
          content {
            headerSection {
              background {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              color
              subheading
              heading
            }
            CTA {
              title
              description
              photo {
                childImageSharp {
                  desktop: fluid(maxWidth: 300, maxHeight: 300, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  mobile: fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              button {
                text
                url
              }
            }
          }
        }
      }
    }
    blockImage: file(relativePath: { eq: "cnc-mill.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    machines: allFile(filter: { sourceInstanceName: { eq: "machines" } }) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
            photo {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    services: allFile(filter: { sourceInstanceName: { eq: "services" } }) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
            photo {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MachinesPage
